@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: local('Work Sans'), url(./assets/fonts/WorkSans-VariableFont_wght.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'WorkSans';
  src: local('Work Sans'), url(./assets/fonts/WorkSans-VariableFont_wght.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'WorkSans';
  src: local('Work Sans'), url(./assets/fonts/WorkSans-VariableFont_wght.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'IntroBold';
  src: local('IntroBold'), url(./assets/fonts/IntroBold.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Intro';
  src: local('IntroRegular'), url(./assets/fonts/WorkSans-VariableFont_wght.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TradeGothic';
  src: local('Trade Gothic'), url(./assets/fonts/Trade-Gothic.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body{
  padding: 0;
  margin: 0;
  overflow: hidden;
}
html {
  height: 100%;
}

body, #root {
  background: #FFF7F3;
  font-family: WorkSans, Roboto, Arial, sans-serif;
  min-height: 100%;
  height: 100%;
}

/* Firebase login overwrites */

div#root .firebaseui-container, div#root .mdl-button {
  font-family: WorkSans, Roboto, Arial, sans-serif;
}

.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery-email-sent,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up {
  box-shadow: none;
  color: #4F4F4F;
}

div#root .firebaseui-card-header {
  border: none;
}
.firebaseui-card-header h1 {
  display: none;
}

div#root .firebaseui-card-actions, div#root .firebaseui-form-actions {
  display: flex;
  flex-direction: column-reverse;
}
div#root .firebaseui-form-links {
  text-align: center;
}
.firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary,
.firebaseui-link.firebaseui-id-secondary-link {
  color: #10069F;
  font-weight: 700;
}
a.firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary,
a.firebaseui-link.firebaseui-id-secondary-link {
  visibility: hidden;
  position: relative;
  height: 0;
  background: beige;
}

button.firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary:hover {
  background: white;
}

a.firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary:after,
a.firebaseui-link.firebaseui-id-secondary-link:after {
  content: 'Forgot password?';
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
  padding-top: 20px;
  font-weight: 700;
}
.firebaseui-id-submit.firebaseui-button.mdl-button.mdl-js-button.mdl-button--raised.mdl-button--colored {
  background-color: #10069F;
  width: 100%;
  border-radius: 0 10px;
  margin-left: 0;
}
button.firebaseui-id-submit.firebaseui-button.mdl-button.mdl-js-button.mdl-button--raised.mdl-button--colored:focus {
  outline: none;
}

div#root .firebaseui-form-actions {
  text-align: center;
}

.firebaseui-card-content p {
  color: #10069F;
  text-align: center;
}


/* Bootstrap overwrites */

.nav-link.active.tab-selected {
  background-color: #FFE1D1;
  color: #84344E;
}
.nav-link.tab-unselected {
  color: #84344E;
}

.navbar.navbar-expand.navbar-dark.bg-dark {
  background-color: #FFE1D1 !important;
}

.navbar.navbar-expand.navbar-dark.bg-dark a {
  color: #84344E;
}
.navbar.navbar-expand.navbar-dark.bg-dark .navbar-text {
  color: #4F4F4F;
}
.container > .nav.nav-tabs {
  border-bottom: none;
}
.nav.nav-tabs .nav.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.input-group-append button {
  background-color: #FFE1D1;
  color: #84344E;
  border-color: #F4886A;
}
.mb-3.input-group input {
  border-color: #F4886A;
  color: #4F4F4F;
}

.mb-3.input-group input::placeholder {
  color: #BFC5DF;
}

.mb-3.input-group input:focus {
  box-shadow: 0 0 5px #F4886A;
}

/** CreditCard */
div#field-wrapper label::after {
  width: 0;
}
div#field-wrapper label {
  width: 100%;
}

/** Tel input */
input[type=tel]:disabled  {
  background: #DADADA !important;
  color: white;
}

/** Date picker react-date-picker */

div.react-date-picker {
  width: 100%;
}

div.react-date-picker div.react-date-picker__wrapper {
  border: #10069F solid thin;
  padding: 8px 10px;
  border-radius: 5px;
}
div.react-date-picker div.react-date-picker__wrapper input:focus {
  color: #A2ACAB;
  background: transparent;
  outline: none;
}
div.react-date-picker div.react-date-picker__wrapper input::placeholder {
  color: #A2ACAB;
}
div.react-date-picker div.react-date-picker__wrapper input:invalid {
  background: transparent;
  color: rgba(255,59,48,0.53);
}

div.react-date-picker.grey-border div.react-date-picker__wrapper {
  border: #A2ACAB solid thin;
}
div.react-date-picker.red-border div.react-date-picker__wrapper {
  border: rgba(255,59,48,0.53) solid thin;
}

